import "twin.macro"
import React from "react"

const ContentSection = () => {
  return (
    <div tw="prose md:prose-xl mx-auto p-5">
      <h3>
        Our Resume is your first opportunity to make a good first impression,
        and you don't have much time to make that impression.
      </h3>
      <strong>Free Resume Review Timings: 9 AM- 10 AM (Monday-Friday)</strong>
      <p>
        <strong tw="font-bold">WHAT DO I DO:🙋&zwj;♀️</strong>{" "}
      </p>
      <p>
        I am passionate about my work. I like to help people on daily basis. I
        help more than 50+ people every day.
      </p>
      <p>
        <br />
        👩&zwj;💻 I would be happy to help you navigate through your Career
        journey.
        <br />
        Let&rsquo;s connect!📌
      </p>
      <p tw="font-bold">HOW DO I HELP JOB SEEKERS:🧑&zwj;💻</p>
      <p>
        ⭐Everyday Free Resume Check-Up
        <br />A resume review is Like a Regular Check-Up But For Your Career.✍
        <br />
        Always Ready For The Next Opportunity.🧑&zwj;🎓
      </p>
      <p>
        ⭐Professional Resume Writing (Premium)
        <br />
        ⭐Free Career Advice
        <br />
        ⭐LinkedIn Stand Out Profiles(Premium)
        <br />
        ⭐Professional LinkedIn profile (Premium)
        <br />
        ⭐Job Strategies &amp; Interview Preparation (Premium)
      </p>

      <strong>
        <p tw="font-bold">WHO AM I:</p>
      </strong>
      <p>
        👩&zwj;💻My Name is Monika Gambhir. I love to help people in the Job
        Search and Career.
        <br />I have almost 5 years of experience in this domain as a freelancer
        Resume writing✍, Career guidance and LinkedIn Profile Polishing. I am
        from both technical and Communication background.👈
      </p>
      <p tw="font-bold">WHY ME:</p>
      <p tw="underline">
        Free Resume Review
        <br />
        Free Career Consultation (Career switch or Job opportunities)
      </p>
      <p>👉Feel Free to connect with Me.👈</p>
      <p>
        💡LinkedIn is a platform for professional
        Networking.👩&zwj;🎓🧑&zwj;🎓👩&zwj;💻🧑&zwj;💻👨&zwj;💼👩&zwj;💼
      </p>
      <p>
        👉Here your Network = Your Net worth👥👥
        <br />
        📌 More Networking = More Learning
        <br />
        📌 More Networking = More Opportunities
      </p>
      <p>
        💡Having a Stand Out LinkedIn profile helps you attract recruiters'
        attention when they're looking for new talent to join their company's
        teams. ..
      </p>
      <p>👉LinkedIn is an ocean of opportunities. 👈</p>
      <p>🔐 Unlock your Opportunities With Help Me.</p>
      <p>💡Jobseeker: Why is Resume writing important ?</p>
      <p>
        💡Me: A resume is an important tool for your job search. It showcases
        your knowledge, your skills, experience, expertise, and accomplishments.
      </p>
      <p>My Expertise👈</p>
      <p>🔐Polish your profile 🔐</p>
      <p>
        ⭐ Stand Out Resume
        <br />
        ⭐Stand Out LinkedIn Profile
        <br />
        ⭐Personal Branding
        <br />⭐ Interview preparation
        <br />⭐ Career Consultation
        <br />
        🔎⭐ Open for HR Collaboration
      </p>
    </div>
  )
}

export default ContentSection
