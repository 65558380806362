import "twin.macro"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HomeWorkShopSection = () => {
  return (
    <div tw="max-w-7xl mx-auto  flex flex-col-reverse md:(grid grid-cols-2 min-height[85vh])  p-5">
      <div tw="flex flex-col justify-center items-center  ">
        <div tw="space-y-3 mt-3">
          <p tw="text-xl md:text-2xl font-medium text-center md:text-left ">
            3 Day Live Workshop
          </p>
          <h1 tw="text-4xl md:text-5xl font-black text-center md:text-left">
            Coming Soon
          </h1>

          <div tw="mx-auto max-w-lg text-base space-y-2 ">
            <p>
              Attend this three days live workshop and get deep insights about
              your career from resume revamp to landing your dream job. This is
              the chance to find your career fit.
            </p>
            <p>
              Boost the chances of landing your landing your dream job by
              attending our live career workshop.
            </p>
          </div>
        </div>
      </div>

      <div tw="flex flex-col justify-center items-center">
        <div tw="md:px-20 py-5">
          <div tw="relative  ml-0 mr-0 sm:mr-10">
            <span tw="absolute top-0 left-0 h-full w-full  mt-2 ml-1 bg-yellow-300 rounded-full"></span>
            <div tw="relative overflow-auto  bg-white border-2  border-yellow-300 rounded-full">
              <StaticImage
                placeholder="blurred"
                src="../../images/profile.png"
                alt="workshop"
              />
            </div>
          </div>
        </div>

        <p tw="text-center text-2xl font-bold ">Monika Gambhir</p>
        <p tw="text-center text-gray-500 ">Professional Career Consultant</p>
        {/* <div tw="rounded-full overflow-hidden border-4 border-yellow-400">
        </div> */}
      </div>
    </div>
  )
}

export default HomeWorkShopSection
