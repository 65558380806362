import "twin.macro"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ClimbSection = () => {
  return (
    <div tw="max-w-7xl mx-auto  flex flex-col md:(grid grid-cols-2 min-height[85vh])  p-5">
      <div tw="flex flex-col justify-center items-center">
        <StaticImage src="../../images/workshop2.jpg" alt="workshop" />
      </div>
      <div tw="flex flex-col justify-center items-center  ">
        <div tw="space-y-3 mt-3">
          <div tw="mx-auto max-w-lg text-base space-y-2 ">
            <p tw="md:text-2xl">
              Climb your ladder to success by taking advantage of all these
              tools. From resume and LinkedIn revamp to landing your dream job.
              We will help you throughout the journey so that you can fulfill
              your dreams. We will assist you in every step of your career.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClimbSection
