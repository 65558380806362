import "twin.macro"
import React from "react"
import HomeWorkShopSection from "../components/workshop/HomeWorkShopSection"
import Layout from "../components/layout"
import WhyAttendSection from "../components/workshop/WhyAttendSection"
import ClimbSection from "../components/workshop/ClimbSection"
import ContentSection from "../components/workshop/ContentSection"

const LiveWorkShop = () => {
  return (
    <Layout>
      <div tw="background-color[#f6f6f6]">
        <HomeWorkShopSection />
        <WhyAttendSection />
        <ContentSection />
        <ClimbSection />
      </div>
    </Layout>
  )
}

export default LiveWorkShop
