import "twin.macro"
import React from "react"
import ServiceCard from "../ui/ServiceCard"

const WhyAttendData = [
  {
    title: "Career",
    content:
      "If you are still not sure about which career to choose and haven't explored your skills and interests yet. Then this is the right place to choose your ideal career. Get career guidance and counseling from our experts and choose your ideal career",
  },
  {
    title: "Resume",
    content:
      " Resume is the first step towards getting your dream job. A resume should just not be a paper giving data about you. It should be specifically tailored for your goals and interests",
  },
  {
    title: "Job interview",
    content:
      "Even the most qualified persons don't get the job they want beacuse of poor interview skills. Master the art of giving interview and brush up your skills by learning from our experts. We will help you throughout your journey to make the best out of you.",
  },
]

const WhyAttendSection = () => {
  return (
    <div tw="max-w-7xl mx-auto flex flex-col justify-center items-center space-y-3 md:space-y-20 p-5 min-h-screen">
      <h3 tw="text-center text-xl md:text-5xl font-black">
        Why should you take part in Our Workshop
      </h3>

      <div tw="grid  gap-5 max-w-3xl">
        {WhyAttendData.map((item, index) => (
          <ServiceCard {...item} key={index} />
        ))}
      </div>
    </div>
  )
}

export default WhyAttendSection
